<script setup>
import {ref} from 'vue'
import {Quill, QuillEditor} from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import request from "@/environment/request"
import ImageUploader from "quill-image-uploader"

Quill.register("modules/imageUploader", ImageUploader)

const props = defineProps({
  modelValue: String,
  toolbar: {
    type: Array, default: [
      [{'header': [1, 2, 3] }],
      [{'size': [ 'small', false, 'large', 'huge' ]}],
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [{'list': 'ordered'}, {'list': 'bullet'}],
      [{ 'indent': '-1'}, { 'indent': '+1' }],
      [{ 'align': [] }],
      [{'script': 'sub'}, {'script': 'super'}],
      [{'color': []}, {'background': []}],
      ['link', 'image'],
      ['video'],
      ['clean']
    ]
  },
})

defineEmits(['update:modelValue'])

const editorOptions = ref({
  modules: {
    toolbar: props.toolbar,
    imageUploader: {
      upload: file => {
        return new Promise((resolve, reject) => {
          const config = {headers: {"Content-Type": "multipart/form-data",}}
          const formData = new FormData()
          formData.append("file", file)
          formData.append("container", 'quill')

          request(true).post("files/upload", formData, config)
            .then((response) => {
              resolve(response.data.url)
            })
            .catch(err => {
              reject("Upload failed: " + err)
              if (err.response && err.response.status == 422) {
                if(err.response.data.errors){
                  alert(err.response.data.errors.file[0])
                }
              }
            })
        })
      }
    }
  }
})
</script>

<template>
  <QuillEditor
    theme="snow"
    :options="editorOptions"
    contentType="html"
    :content="modelValue"
    @update:content="$emit('update:modelValue', $event)"/>
</template>
