<template>
  <div class="container">
    <div v-if="!onWebsite" class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <Breadcrumb
          :pages="[
            {
              name: 'Resource List',
              href: '/resources',
              current: false,
            },
            { name: 'Edit Resource', href: null, current: true },
          ]"
        />
      </div>
      <div v-if="resource && !onWebsite" class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
        <router-link :to="`/resources/${resource.id}`">
          <button
            class="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            type="button"
          >
            View
          </button>
        </router-link>
      </div>
    </div>

    <div class="mt-8 flex flex-col">
      <div class="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
        <form action="#" @submit.prevent="saveResource">
          <div
            :class="{
              'shadow sm:rounded-md sm:overflow-hidden': !onWebsite,
            }"
          >
            <div
              :class="{
                'bg-white py-6 px-4 space-y-6 sm:p-6': !onWebsite,
              }"
            >
              <div class="grid grid-cols-6 gap-6">
                <!-- Website -->
                <div v-if="!onWebsite" class="col-span-6 sm:col-span-3">
                  <label class="block text-sm font-medium text-gray-700"
                    >Website
                    <Asterisk />
                  </label>
                  <select
                    v-model="form.website_id"
                    class="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2.5 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    @change="getGroups(true)"
                  >
                    <option value="">...</option>
                    <option v-for="website in websites" :key="website" :value="website.id">
                      {{ website.name }}
                    </option>
                  </select>
                  <p v-if="errors.website_id" class="mt-2 text-sm text-red-500">
                    {{ errors.website_id[0] }}
                  </p>
                </div>

                <!-- Group -->
                <div v-if="!onWebsite" class="col-span-6 sm:col-span-3">
                  <label class="form-input-label" for="group_id">Group</label>
                  <MultiSelect
                    id="group_id"
                    ref="multiSelect"
                    v-model="form.group_id"
                    :options="groups"
                    label="name"
                    placeholder="Select Group"
                    trackBy="name"
                    valueProp="id"
                  />
                  <p v-if="errors.group_id" class="mt-2 text-sm text-red-500">
                    {{ errors.group_id[0] }}
                  </p>
                </div>

                <!-- Title -->
                <div class="col-span-6 sm:col-span-3">
                  <template v-if="!onWebsite">
                    <input-component
                      id="title"
                      v-model="form.title"
                      :ErrorMessage="errors.title ? errors.title[0] : null"
                      asterisk
                      labelText="Title"
                      placeholder="Title"
                    />
                  </template>
                  <template v-else>
                    <AppInput v-model="form.title" asterisk label="Title" />
                    <AppInputError v-if="errors.title" :message="errors.title[0]" class="mt-2" />
                  </template>
                </div>

                <!-- Slug -->
                <div class="col-span-6 sm:col-span-3">
                  <template v-if="!onWebsite">
                    <input-component
                      id="slug"
                      v-model="form.slug"
                      :ErrorMessage="errors.slug ? errors.slug[0] : null"
                      labelText="Slug"
                      placeholder="Slug"
                    />
                  </template>
                  <template v-else>
                    <AppInput v-model="form.slug" label="Slug" />
                    <AppInputError v-if="errors.slug" :message="errors.slug[0]" class="mt-2" />
                  </template>
                </div>

                <!-- Type -->
                <div class="col-span-6 sm:col-span-3">
                  <label v-if="!onWebsite" class="form-input-label" for="type_id"
                    >Type
                    <Asterisk />
                  </label>
                  <MultiSelect
                    id="type_id"
                    ref="multiSelect"
                    v-model="form.resource_type_id"
                    :options="types"
                    label="name"
                    placeholder="Select Resource Type"
                    required
                    trackBy="name"
                    valueProp="id"
                  />
                  <p v-if="errors.resource_type_id" class="mt-2 text-sm text-red-500">
                    {{ errors.resource_type_id[0] }}
                  </p>
                </div>

                <!-- Author -->
                <div class="col-span-6 sm:col-span-3">
                  <template v-if="!onWebsite">
                    <input-component
                      id="author"
                      v-model="form.author"
                      :ErrorMessage="errors.author ? errors.author[0] : null"
                      asterisk
                      labelText="Author"
                      placeholder="Author"
                    />
                  </template>
                  <template v-else>
                    <AppInput v-model="form.author" asterisk label="Author" />
                    <AppInputError v-if="errors.author" :message="errors.author[0]" class="mt-2" />
                  </template>
                </div>

                <!-- Description -->
                <div class="col-span-6">
                  <fieldset>
                    <label class="block text-sm font-medium text-gray-700" for="street-address">Description</label>
                    <AppTextEditor v-model="form.description" />
                    <p v-if="errors.description" class="mt-2 text-sm text-red-500">
                      {{ errors.description[0] }}
                    </p>
                  </fieldset>
                </div>

                <!-- summary -->
                <div class="col-span-6 sm:col-span-3">
                  <template v-if="!onWebsite">
                    <input-component
                      id="summary"
                      v-model="form.summary"
                      :ErrorMessage="errors.summary ? errors.summary[0] : null"
                      labelText="Summary"
                      placeholder="Summary"
                    />
                  </template>
                  <template v-else>
                    <AppInput v-model="form.summary" label="Summary" />
                    <AppInputError v-if="errors.summary" :message="errors.summary[0]" class="mt-2" />
                  </template>
                </div>
                <!-- Alternative Text -->
                <div class="col-span-6 sm:col-span-3">
                  <template v-if="!onWebsite">
                    <input-component
                      id="alt"
                      v-model="form.alt"
                      :ErrorMessage="errors.alt ? errors.alt[0] : null"
                      labelText="Alternative Text"
                      placeholder="Alternative Text"
                    />
                  </template>
                  <template v-else>
                    <AppInput v-model="form.alt" label="Alternative Text" />
                    <AppInputError v-if="errors.alt" :message="errors.alt[0]" class="mt-2" />
                  </template>
                </div>

                <!-- Cover Photo -->
                <div class="col-span-6">
                  <DropFile
                    :coverLocation="form.cover_location"
                    :file="form.cover"
                    customCoverLocation
                    @changeCoverLocation="($event) => (form.cover_location = $event)"
                    @fileSelected="onSelectFile"
                  />
                </div>

                <!-- Published -->
                <div v-if="userRole !== 'site users' && hasAccessToAdminPanel" class="col-span-3 sm:col-span-3">
                  <div class="flex items-center space-x-4">
                    <AppCheckbox id="is_active" v-model:checked="form.is_published" name="is_published" />
                    <label class="block text-sm font-medium text-gray-700" for="street-address">Published</label>
                  </div>
                  <p v-if="errors.is_published" class="mt-2 text-sm text-red-500">
                    {{ errors.is_published[0] }}
                  </p>
                </div>

                <!-- Featured in Home Page -->
                <div v-if="!onWebsite && hasAccessToAdminPanel" class="col-span-3 sm:col-span-3">
                  <div class="flex items-center space-x-4">
                    <AppCheckbox id="is_active" v-model:checked="form.featured" name="featured" />
                    <label class="block text-sm font-medium text-gray-700" for="street-address"
                      >Featured in Home Page</label
                    >
                  </div>
                  <p v-if="errors.featured" class="mt-2 text-sm text-red-500">
                    {{ errors.featured[0] }}
                  </p>
                </div>

                <!-- Featured in Group -->
                <div v-if="userRole !== 'site users' && hasAccessToAdminPanel" class="col-span-3 sm:col-span-3">
                  <div class="flex items-center space-x-4">
                    <AppCheckbox id="is_active" v-model:checked="form.group_featured" name="featured" />
                    <label class="block text-sm font-medium text-gray-700" for="street-address"
                      >Featured in group</label
                    >
                  </div>
                  <p v-if="errors.group_featured" class="mt-2 text-sm text-red-500">
                    {{ errors.group_featured[0] }}
                  </p>
                </div>

                <!-- From United Way -->
                <div v-if="!onWebsite && hasAccessToAdminPanel" class="col-span-3 sm:col-span-3">
                  <div class="flex items-center space-x-4">
                    <AppCheckbox id="is_active" v-model:checked="form.from_united_way" name="featured" />
                    <label class="block text-sm font-medium text-gray-700" for="street-address">From United Way</label>
                  </div>
                  <p v-if="errors.from_united_way" class="mt-2 text-sm text-red-500">
                    {{ errors.from_united_way[0] }}
                  </p>
                </div>

                <!-- Pinned -->
                <div v-if="hasAccessToAdminPanel" class="col-span-3 sm:col-span-3">
                  <div class="flex items-center space-x-4">
                    <AppCheckbox id="is_pinned" v-model:checked="form.is_pinned" name="is_pinned" />
                    <label class="block text-sm font-medium text-gray-700" for="is_pinned">Pin it</label>
                  </div>
                  <p v-if="errors.is_pinned" class="mt-2 text-sm text-red-500">
                    {{ errors.is_pinned[0] }}
                  </p>
                </div>

                <!-- Audience -->
                <div class="col-span-6">
                  <fieldset>
                    <legend class="text-base font-medium text-gray-900">Audience</legend>
                    <MultiCheckbox
                      v-model="form.audience_id"
                      :error="errors.audience_id ? errors.audience_id[0] : null"
                      :options="audiences"
                    />
                  </fieldset>
                </div>

                <!-- Subject Area -->
                <div class="col-span-6">
                  <fieldset>
                    <legend class="text-base font-medium text-gray-900">Subject Area</legend>
                    <MultiCheckbox
                      v-model="form.subject_area_id"
                      :error="errors.subject_area_id ? errors.subject_area_id[0] : null"
                      :options="subjectAreas"
                    />
                  </fieldset>
                </div>

                <!-- Category -->
                <div class="col-span-6">
                  <fieldset>
                    <legend class="text-base font-medium text-gray-900">Category</legend>
                    <MultiCheckbox
                      v-model="form.category_id"
                      :error="errors.category_id ? errors.category_id[0] : null"
                      :options="categories"
                    />
                  </fieldset>
                </div>
              </div>
            </div>
            <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <button
                :class="{
                  'bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500':
                    !onWebsite,
                  'button   primary': onWebsite,
                  'disabled:!cursor-wait': isDisabled,
                }"
                :disabled="isDisabled"
                type="submit"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
      <DropZoneComponent
        :deleting="true"
        :files="resource.files"
        :itemId="onWebsite ? id : null"
        :uploading="true"
        modelType="App\Models\Resource"
      />
    </div>
  </div>
</template>

<script setup>
import store from '@/store'
import request from '@/environment/request'
import { onMounted, reactive, ref, watch } from 'vue'
import MultiSelect from '@vueform/multiselect'
import { useRoute, useRouter } from 'vue-router'
import DropFile from '@/components/shared/DropFile'
import AppCheckbox from '@/components/shared/Checkbox'
import Breadcrumb from '@/components/shared/Breadcrumb'
import AppTextEditor from '@/components/shared/TextEditor'
import InputComponent from '@/components/shared/InputComponent'
import DropZoneComponent from '@/components/shared/DropZoneComponent'
import MultiCheckbox from '@/components/shared/MultiCheckbox'
import audiencesService from '@/services/audience'
import subjectAreasService from '@/services/subject-area'
import categoriesService from '@/services/category'
import websitesService from '@/services/website'
import permsMixin from '@/services/permsMixin'
import Asterisk from '@/components/shared/Asterisk'

// Imports for website section
import AppInput from '@js/Components/Shared/Input'
import AppInputError from '@js/Components/Shared/InputError'

const errors = ref([])
const groups = ref([])
const types = ref([])
const cover = ref(null)
const audiences = ref([])
const subjectAreas = ref([])
const categories = ref([])

const router = useRouter()
const route = useRoute()

const props = defineProps({
  onWebsite: Boolean,
  group: Object,
  resourceId: Number,
  userRole: String,
})

const form = reactive({
  title: '',
  alt: '',
  cover: null,
  author: null,
  audience_id: [],
  group_id: '',
  summary: '',
  website_id: '',
  description: '',
  featured: false,
  subject_area_id: [],
  category_id: [],
  group_featured: false,
  from_united_way: false,
  is_pinned: false,
  resource_type_id: null,
  id: props.onWebsite ? props.resourceId : route.params.id,
  is_published: false,
  cover_location: 0,
})

const resource = ref('')

let id = props.onWebsite ? props.resourceId : route.params.id
const websites = ref([])

onMounted(() => {
  request(!props.onWebsite)
    .get('resource-types')
    .then((response) => (types.value = response.data))

  request(!props.onWebsite)
    .get('resources/' + id)
    .then((response) => {
      resource.value = response.data.data
      assignValue(resource.value)
      getGroup(resource.value.website.id)
      if (!resource.value.group?.id) {
        getSubjectAreas(form.group_id)
        getCategories(form.group_id)
      }
    })

  websitesService.getAll().then((data) => (websites.value = data))
  audiencesService.getAll().then((data) => (audiences.value = data))
})

function getGroup(websiteId) {
  request(!props.onWebsite)
    .get(`get-groups?website_id=${websiteId}`)
    .then((response) => (groups.value = response.data.data))
}

const isDisabled = ref(false)

function saveResource() {
  isDisabled.value = true
  const config = { headers: { 'Content-Type': 'multipart/form-data' } }
  let formData = new FormData()

  formData.append('author', form.author)
  formData.append('title', form.title)
  formData.append('alt', form.alt)
  formData.append('slug', form.slug)
  formData.append('cover', fileSelected.value === true ? form.cover : '')
  formData.append('group_id', form.group_id === 'null' || form.group_id === null ? '' : form.group_id)
  formData.append('description', form.description)
  formData.append('featured', form.featured)
  formData.append('summary', form.summary)
  formData.append('group_featured', form.group_featured)
  formData.append('from_united_way', form.from_united_way)
  formData.append('is_pinned', form.is_pinned)
  formData.append('resource_type_id', form.resource_type_id)
  formData.append('is_published', form.is_published)
  formData.append('category_id', form.category_id)
  formData.append('audience_id', form.audience_id)
  formData.append('subject_area_id', form.subject_area_id)
  formData.append('cover_location', form.cover_location)

  request(!props.onWebsite)
    .post(`resources/${form.id}`, formData, config)
    .then(() => {
      store.commit('displaySuccessModal', 'Successfully updated.')
      if (props.onWebsite) {
        _toast.success({
          title: 'Updated resource',
          message: 'Resource has been successfully updated',
        })
        setTimeout(() => (location.href = `/group/${props.group.slug}/resource/${form.slug}`), 500)
      } else {
        router.push({ path: `/resources/${form.id}` })
      }
    })
    .catch((err) => {
      isDisabled.value = false
      if (err.response && err.response.status === 422) {
        errors.value = err.response.data.errors
      }
    })
}

function selectedCover($event) {
  const formData = new FormData()
  formData.append('file', $event.target.files[0])
  const headers = { 'Content-Type': 'multipart/form-data' }
  request(!props.onWebsite)
    .post('upload', formData, { headers })
    .then((res) => {
      cover.value = res.data.path
      form.cover = cover.value
    })
    .catch((error) => (errors.value = error.response.data.errors))
}

function assignValue(resource) {
  form.id = resource.id
  form.website_id = resource.website.id
  form.cover = resource.cover
  form.title = resource.title
  form.alt = resource.alt
  form.slug = resource.slug
  form.summary = resource.summary !== null ? resource.summary : ''
  form.author = resource.author
  form.group_id = resource?.group?.id ?? ''
  form.is_published = resource.is_published
  form.description = resource.description
  form.featured = resource.featured === 1
  form.group_featured = resource.group_featured === 1
  form.from_united_way = resource.from_united_way === 1
  form.is_pinned = resource.is_pinned === 1
  form.resource_type_id = resource.resourceType.id
  form.audience_id = resource.audiences.map((item) => item.id)
  form.cover_location = resource.cover_location
}

function getSubjectAreas(groupId) {
  subjectAreasService.getAll(groupId).then((data) => {
    subjectAreas.value = data
    assignSubjectArea()
  })
}

function getCategories(groupId) {
  categoriesService.getAll(groupId).then((data) => {
    categories.value = data
    assignCategory()
  })
}

const fileSelected = ref(false)

function onSelectFile(files) {
  form.cover = files[0]
  fileSelected.value = true
}

function assignSubjectArea() {
  let subjectAreasId = subjectAreas.value.map((item) => item.id)
  let selectedSubjectAreasId = resource.value.subject_areas.map((item) => item.id)

  form.subject_area_id = selectedSubjectAreasId.filter((item) => {
    return subjectAreasId.includes(item)
  })
}

function getGroups() {
  request(!props.onWebsite)
    .get(`get-groups?website_id=${form.website_id}`)
    .then((res) => {
      groups.value = res.data.data
      form.group_id = ''
    })
}

function assignCategory() {
  let categoriesId = categories.value.map((item) => item.id)
  let selectedCategoriesId = resource.value.categories.map((item) => item.id)

  form.category_id = selectedCategoriesId.filter((item) => {
    return categoriesId.includes(item)
  })
}

watch(
  () => form.group_id,
  () => {
    getSubjectAreas(form.group_id)
    getCategories(form.group_id)
  }
)
const { hasAccessToAdminPanel } = permsMixin.setup()
</script>
